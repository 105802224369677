<template>
  <footer class="footer-area footer-style-01 bg_color--6">
    <div class="im-call-to-action-area ptb--70 im-separator">
      <v-container>
        <v-row class="align-center">
          <v-col xl="6" lg="8" md="6" sm="12" cols="12">
            <div class="inner">
              <h2 class="white--text mb--0">
                {{ $t('becomePartner') }}
              </h2>
            </div>
          </v-col>
          <v-col lg="4" offset-xl="2" md="6" sm="12" cols="12">
            <div class="text-left call-to-cation-tbn text-md-right mt_md--20 mt_sm--20">
              <router-link class="btn-default btn-large btn-border btn-opacity" to="contact">{{ $t('contactUs')
                }}</router-link>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="footer-wrapper ptb--70">
      <v-container class="container">
        <v-row>
          <v-col lg="4" md="4" sm="6" cols="12">
            <div class="ft-text">
              <div class="logo">
                <a href="/"><img :src="logo" alt="brand image" /></a>
              </div>
              <p>
                Copyright © Simonini Software 2013 -
                {{ new Date().getFullYear() }}.
                <!-- <a href="/">Imroz</a> VueJs Template. Built with love in
                Bangladesh All rights reserved. -->
              </p>
            </div>
          </v-col>
          <v-col lg="2" offset-xl="1" md="6" sm="6" cols="12" class="mt_mobile--40">
            <div class="footer-link">
              <h4>Links</h4>
              <ul class="ft-link">
                <li>
                  <router-link to="#">{{ $t('developerArea') }}</router-link>
                </li>
                <li><router-link to="#">{{ $t('jobVacancies') }}</router-link></li>
              </ul>
            </div>
          </v-col>
          <v-col lg="2" md="2" sm="6" cols="12" class="mt_md--40 mt_sm--40">
            <div class="footer-link">
              <h4>{{ $t('company') }}</h4>
              <ul class="ft-link">
                <li><router-link to="#">{{ $t('aboutUs') }}</router-link></li>
                <li><router-link to="#">{{ $t('services') }}</router-link></li>
                <li><router-link to="#">{{ $t('contact') }}</router-link></li>
                <li>
                  <router-link to="#">{{ $t('privacyPolicy') }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
          <v-col lg="3" md="3" sm="6" cols="12" class="mt_md--40 mt_sm--40">
            <div class="footer-link">
              <h4>{{ $t('contactUs') }}</h4>
              <ul class="ft-link">
                <li>
                  <i class="fas fas fas fa-phone"></i>&nbsp;<a href="tel:+5511980614377" style="font-size: 14px">+55
                    (11) 98061-4377</a>
                </li>

                <li>
                  <i class="fas fas fa-envelope-square"></i>&nbsp;<a href="contato@simoninisoftware.com.br"
                    style="font-size: 14px">contato@simoninisoftware.com.br</a>
                </li>

                <li>
                  <i class="far fa-building"></i>&nbsp;<a href="https://maps.app.goo.gl/oKgfLNbhMFZKMWoc8"
                    target="_blank" style="font-size: 14px">{{ $t('addressBrazil') }}</a>
                </li>

                <li>
                  <i class='far fa-building'></i>&nbsp;<a href="https://maps.app.goo.gl/JJjWfUwgu3YMktBx8"
                    target="_blank" style="font-size: 14px;">{{ $t('addressPortugal') }}</a>
                </li>
              </ul>
              <div class="social-share-inner mt--20">
                <ul class="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                  <li v-for="(social, i) in socialList" :key="i">
                    <a :href="social.url" target="_blank">
                      <i class="fab" :class="social.icon"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </footer>
</template>

<script src="https://kit.fontawesome.com/a076d05399.js" crossorigin="anonymous"></script>
<script>
export default {
  data() {
    return {
      logo: require("../../assets/images/logo/simoninovo-09.png"),
      socialList: [
        // {
        //   icon: "fa-facebook-f",
        //   url: "https://www.facebook.com/",
        // },
        {
          icon: "fa-linkedin-in",
          url: "https://www.linkedin.com/company/simonini-software",
        },
        // {
        //   icon: "fa-twitter",
        //   url: "https://twitter.com/",
        // },
        {
          icon: "fa-instagram",
          url: "https://www.instagram.com/simoninisoftware/",
        },
      ],
    };
  },
};
</script>
